import $ from "jquery";

const active = '-active',
      trigger = '#js-search-trigger',
      target  = '#js-search-target';

$(trigger).on('click', (event) => {
  $(event.currentTarget).toggleClass(active);
  $(target).fadeToggle();

   return false;
});
